import React from "react";
import ReferEarn from "../components/refer-and-earn/ReferEarn";
import ReferTabs from "../components/refer-and-earn/ReferTabs";

const ReferAndEarn = () => {
  return (
    <>
      <ReferEarn />
      
    </>
  );
};

export default ReferAndEarn;
