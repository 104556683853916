import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import Carousel from "./Carousel";
import video from "../../../assets/image/mobile-home.mp4"
import FirstSec from "../sky-high/HeroSec";
import Banner from "../../../assets/image/mobHome/homepage-banner1-mob.jpg";
import Banner2 from "../../../assets/image/mobHome/homepage-banner2-mob.jpg";
import Banner3 from "../../../assets/image/mobHome/homepage-banner3-mob.jpg";
// import FirstSec from "../sky-high/HeroSec";
const MotnHead = motion(Heading);

const MainSec = () => {
  return (
    <>
      {/* <Carousel />  */}
      <div className="hompage_banner">
        <video loop autoPlay muted>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
        </video>
      </div>
       {/* <FirstSec
        banner={Banner}
        Banner2={Banner2}
        Banner3={Banner3}
        videoUrl={video} mobileHome="mob_home"/> */}
      <Flex
        w="100%"
        h="27.6rem"
        direction="row"
        py='1.5rem'
        pt="2rem"
        style={{
          height: "max-content",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          width:" max-content",
          padding: "2rem 4rem 1rem",
          transform: `translateY(${-30}px)` 
        }} 
      >
        <Text textAlign="center">
          <MotnHead
            fontSize="1.5em"
            fontFamily="avenir"
            letterSpacing="wide"
            textAlign="center"
            // initial={{ x: 460, y: 350 }}
            // animate={{  }}
            transition={{ delay: 3, duration: 5, type: "just" }}

          >
            TRANSFORMING
          </MotnHead> 
          <MotnHead
            fontSize="1.5em"
            fontFamily="avenir"
            letterSpacing="wide"
            textAlign="center"
          // initial={{ opacity: 0, x: [375, 375, 375], y: [350, 250, 250] }}
          // transition={{ delay: 3, duration: 5, type: "just" }}

          >
            NORTH CHENNAI
          </MotnHead>
          <MotnHead
            fontSize="1.5em"
            fontFamily="avenir"
            letterSpacing="wide"
            // initial={{ opacity: 0, x: [450, 400], y: [450, 250] }}
            // transition={{ delay: 3, duration: 5, type: "just" }}
            textAlign="center"
            zIndex="4"
            // mt="1rem"
          >
            SKYLINE
          </MotnHead>
          <Text

            fontWeight="400"
            fontFamily="veralaRound"
            fontSize="1rem"
            width="100%"
            mt="1rem"
          >
            Arete Homes your wish for a <br /> smart living, NOW GRANTED
          </Text>
          <Button

            w="162px"
            h="51px"
            fontSize="16px"
            fontFamily="avenir"
            bgGradient="linear(to-b, #B88746 ,#DFBD69)"
            color="white"
            mt="1.4em"
            _hover={{
              bgGradient: "linear(to-b, #DFBD69, #B88746)",
            }}
            _active={{ bgGradient: "linear(to-b, #B88746 ,#DFBD69)" }}
          >
            EXPLORE NOW
          </Button>
        </Text>{" "}

      </Flex>
    </>
  );
};

export default MainSec;
