import React, { useLayoutEffect } from "react";
import AretehomesInvest from "../components/invest/AretehomesInvest";
import Banner from "../components/invest/Banner";
import { revealAgain, revealOnce } from "../hooks/util";
import banner from "../assets/image/8desktop-banner.jpg";
import DownloadBtn from "../components/sky-high/DownloadBtn";
import pdf  from "../assets/pdf/Investment Brochure - Prime LifeSpace projects.pdf"

const AretehomesInvestSc = () => {
  useLayoutEffect(()=>{
    window.addEventListener("scroll", revealAgain);
    window.addEventListener("scroll", revealOnce);
    return()=>{
      window.removeEventListener("scroll", revealAgain);
      window.removeEventListener("scroll", revealOnce);
    }
  });

  return (
    <>
      {" "}
      <DownloadBtn AreteHomePdf={pdf}/>
      <Banner banner={banner} />
      <AretehomesInvest />
    </>
  );
};

export default AretehomesInvestSc;
