import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { motion, useAnimation, useInView } from "framer-motion";
import React, { useState } from "react";

import { useRef } from "react";
import Slider from "react-slick";

const OurAlianceHome = ({ AlianceLogo }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [slider, setSlider] = useState(null);
    const settings = {
        // className: "center",
        centerMode: true,
        autoplay: true,
        infinite: true,
        draggable: true,
        speed: 500,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Flex
            ref={ref}
            w="100%"
            h="100%"
            direction="column"
            align="center"
            justify="center"

        >

            <Box
                transform={isInView ? "scale(1)" : "scale(0.6)"}
                opacity={isInView ? "1" : "0"}
                transition="all 2s 1s"
                position={"relative"}
                height={"100%"}
                width={"full"}
                overflow={"hidden"}
                mb="1rem"
            >
                {/* CSS files for react-slick */}
                <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                {/* Slider */}
                <Slider {...settings} ref={(slider) => setSlider(slider)}>
                    {AlianceLogo.map((item, index) => (
                        <Flex key={index} px=".312rem" pl="1rem" direction="column" textAlign="center"  _hover={{ border: "none" }}>
                            <Image
                                className="expandcar reveal"
                                src={item}
                                w="90%"
                                style={{maxWidth:"90%"}}
                            />
                        </Flex>
                    ))
                    }
                </Slider>
            </Box>
        </Flex>
    );
};

export default OurAlianceHome;
