import React from "react";
import Blog from "../components/blog/Blog";

const BlogSc = () => {
  return (
    <>
      <Blog />
    </>
  );
};

export default BlogSc;
