import React from "react";
import ChannelForm from "../components/mob/channel-partner/ChannelForm";
import MainSec from "../components/mob/channel-partner/MainSec";
import DownloadBtn from "../components/mob/sky-high/DownloadBtn";
import Pdf from "../assets/pdf/Channel Partner Brochure PGC Membership.pdf";

const MobChannelPartner = () => {
  return (
    <>
     <DownloadBtn AreteHomePdf={Pdf}/>
      <MainSec />
      <ChannelForm />
    </>
  );
};

export default MobChannelPartner;
