import React from "react";
import Blog from "../components/mob/blog/Blog";

const MobBlogSc = () => {
  return (
    <>
      <Blog />
    </>
  );
};

export default MobBlogSc;
