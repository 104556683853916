import React from "react";
import ChannelForm from "../components/channel-partner/ChannelForm";
import MainSec from "../components/channel-partner/MainSec";
import DownloadBtn from "../components/sky-high/DownloadBtn";
import Pdf from "../assets/pdf/Channel Partner Brochure PGC Membership.pdf"

const ChannelPartner = () => {
  return (
    <>
      <DownloadBtn AreteHomePdf={Pdf}/>
      <MainSec />
      <ChannelForm />
    </>
  );
};

export default ChannelPartner;
