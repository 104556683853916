import React from "react";
import AreteSec from "../components/comercial Properties/AreteSec";
import Banner from "../components/comercial Properties/Banner";
import BottomMenu from "../components/comercial Properties/BottomMenu";
import banner from "../components/commercial-properties imgs/ARETE PLAZA1_11zon.png";
import banner2 from "../components/commercial-properties imgs/banner2.jpg"; 

const CommercialProperties = () => {
  return (
    <>
      <Banner
      banner={banner}
      banner2={banner2}
       COMMERCIAL = "COMMERCIAL"
        PROPERTIES="PROPERTIES"/>
      <BottomMenu />
      <AreteSec />
    </>
  );
};

export default CommercialProperties;
