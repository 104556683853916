import React, { useLayoutEffect } from "react";
import AretehomesInvest from "../components/mob/invest/AretehomesInvest";
import Banner from "../components/mob/invest/Banner";
import { revealAgain, revealOnce } from "../hooks/util";
import banner from "../assets/image/8Mob-banner.jpg";
// import DownloadBtn from "../components/sky-high/DownloadBtn";
import Pdf from "../assets/pdf/Investment Brochure - Prime LifeSpace projects.pdf"
import DownloadBtn from "../components/mob/sky-high/DownloadBtn";

const AretehomesInvestMobSc = () => {
  useLayoutEffect(()=>{
    window.addEventListener("scroll", revealAgain);
    window.addEventListener("scroll", revealOnce);
    return()=>{
      window.removeEventListener("scroll", revealAgain);
      window.removeEventListener("scroll", revealOnce);
    }
  });

  return (
    <>
      {" "}
      <DownloadBtn  AreteHomePdf={Pdf}/>
      <Banner banner={banner} />
      <AretehomesInvest />
    </>
  );
};

export default AretehomesInvestMobSc;
