import { Flex, Heading, Image, Divider, Box, Button, Text, Spacer, IconButton } from "@chakra-ui/react";
import { motion, useAnimation, useInView } from "framer-motion";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import pgclogo from "../../arete-homes imgs/Asset 1.png"
// src="/images/award1.png"
import award1 from "../../../assets/image/award1.png";
import award2 from "../../../assets/image/award2.png";
import award3 from "../../../assets/image/award3.png";
// import multiSports from "../../homepage-imgs/2 - Multi Sports Arena.jpg";
// import swim from "../../homepage-imgs/3 - Swimming Pool High Res.tif";
// import hall from "../../homepage-imgs/4-PGC Banquet Hall .jpg";
// import golf from "../../homepage-imgs/5 - Open Golf Putting Area.png";
// import bar from "../../homepage-imgs/6-PGC Sports Bar.jpg";
// import gym from "../../homepage-imgs/7 - gym.tif";
//import pgclogo from "/arete-homes imgs/Asset 1.png";

const BrandHome = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: true,
        margin: "0px 100px -50px 0px",
    });
    const [assetIndex, setAssetIndex] = useState(0);
    const assets = [
        { title: "Times Realty Icon The Best", sub_title: "Upcoming Township", img: award1, },
        { title: "  The Economic Times Best Realty", sub_title: " Brand 2020-21", img: award2, },
        { title: " FICCI Best Gated Community", sub_title: "Of the Year 2021", img: award3, },
        { title: "Times Realty Icon The Best", sub_title: "Upcoming Township", img: award1, },
        { title: "  The Economic Times Best Realty", sub_title: " Brand 2020-21", img: award2, },
        { title: " FICCI Best Gated Community", sub_title: "Of the Year 2021", img: award3, },
    ]
    const nextHandle = () => {
        if (assetIndex === 3) {
            setAssetIndex(0);
        }
        else {
            setAssetIndex(assetIndex + 1);
        }
    }
    const prevHandle = () => {
        if (assetIndex === 0) {
            setAssetIndex(3);
        }
        else {
            setAssetIndex(assetIndex - 1);
        }

    }
    useEffect(() => {
        setTimeout(() => {
            nextHandle();
        }, 3000);
        return () => {
            clearTimeout();
        }
    });

    return (
        <Flex ref={ref} w="100%" h="100%" py="1.875rem" mt="3.125rem" pl="100px"
            bgImage='linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/images/bg-box.jpg")'
            bgRepeat="no-repeat"
            bgPos="center"
            bgSize="cover" className="ponner-wrapper">
            <Flex w="100%" h="100%" direction="column">
                <Flex w="100%" h="100%" className="poinner-grid-wrap">
                    <Flex
                        w="60%"
                        h="100%"
                        className="poinner-grid-img"
                    >
                        <Image className="expandcar reveal" w="100%" h="25rem" src={assets[assetIndex].img} />{" "}
                    </Flex>
                    <Flex align="flex-start" pr="100px" overflow="hidden" className="poinner-grid-right">
                        <Flex
                            className="slidein-top reveal"
                            transition={"all 2s 2s"}
                            h="100%"
                            direction="column"
                            align="center"
                            px="1.563rem"
                        >
                            <Divider

                                h="8.375rem"
                                orientation="vertical"
                                border="3px solid"
                                borderColor="#DFBD69"
                                bgColor="#DFBD69"
                            />
                            <Box
                                height="30px"
                                width="30px"
                                backgroundColor="#DFBD69"
                                border="3px solid white"
                                boxShadow="0px 0px 0px 3px #B88746"
                                borderRadius="50%"
                                my="1"
                            />
                        </Flex>
                        <Flex
                            pos="relative"
                            className="fadein revealOnce fadein-wrap"
                            w="100%"
                            h="25rem"
                            direction="column"
                            align="flex-start"
                            justify="center"
                        >

                            <Text fontFamily="veralaRound" fontSize="16px" textAlign="center">
                                {assets[assetIndex].title} <br />
                                {assets[assetIndex].sub_title}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default BrandHome;
