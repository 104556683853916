import { Flex, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import banner from "../../../assets/image/Our-expertise-mobile-banner-image.jpeg";

const MtnFlx = motion(Flex);
const MtnHd = motion(Heading);

const AboutBanner = () => {
  return (
    <Flex w="100%" h="100vh" pos="relative"  className="expertise-wrap">
      <MtnFlx
        initial={{ scale: 0.6 }}
        animate={{ scale: 1 }}
        transition={{ duration: 2, type: "just" }}
        w="100%"
        h="100%"
        pos="absolute"
        bgImage={banner}
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        align="center"
        justify="center"
      ></MtnFlx>
      <MtnHd
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, type: "just" }}
        textAlign="center"
        pos="absolute"
        top="63%"
        left="50%"
        transform="translateX(-50%)"
        fontFamily="avenir"
        fontSize="2rem"
        color="white"
        fontweight="bold"
        style={{
          textShadow:"0 0 2px black"
        }}
      >
        OUR EXPERTISE
      </MtnHd>
    </Flex>
  );
};

export default AboutBanner;
